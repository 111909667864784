.displayEmailPannel{
    position:absolute;
    top:50%;
    right: 50%;
        transform: translate(50%,-50%);
    background-color: #0F172A;
    padding:1vh 3vw;
    border-radius: 15px;
    width:50vw;
    min-height:80vh;
    z-index: 10000
}

@media screen and (max-width: 1200px) {
.displayEmailPannel{
    width:90vw
}
}