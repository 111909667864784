.blur-effect {
    filter: blur(40px); /* Adjust the pixel value as needed */
  }
footer{
    position:absolute;
    bottom:0px;
    width:100%;
}
/* Custom CSS file */
.table > :not(caption) > * > * {
  color :white !important
}
.single-item{
  cursor:pointer
}
.page-link{
  color : white !important;
  cursor: pointer;
}
.paginate_button{
  cursor: pointer;
  font-size: 0.6em !important;
}

#DropdownTagsFilterMenu{
  transform : translateY(10px);
  max-height: 30vh;
  overflow-y: scroll;
  cursor:pointer
}