
.ImportWrapper{
    width: 50vw;
    height: fit-content;
    position: absolute;
    top:0vh;
    right:25vw;
    background : #0F172A;
    padding:3vh 2vw;
}
.menuItem{
    flex-direction: column;
    width: 100%;
    position: absolute;
    display: flex;
    background-color: #0F172A;
    z-index: 100;
    padding-bottom : 2vh;
    cursor:pointer;

}
.menuItem .menuSpan{
    width:100%;
    border : 0.1px solid #5b6171;
    align-items: center;
    border-left: none;
    border-right: none;
    padding:1vh 2vw;
    color: white;
    font-family: inherit;
    font-size: inherit;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.menuItem .menuSpan:hover {
    background :#9094A1
}
.menuItem .menuSpan label{
    font-size: 13px;
    color: #5b6171
}
#inputTitle{
    background-color: #1C2438 !important;
  
}
#dropdownList{
    background-color: #1C2438 !important;
}
.tagify__dropdown__item{
    background-color: #0F172A;
}
.form-control{
    background-color: #1C2438 !important;

}
