
#emailSubjectWrapper ,#regeneratePrompt{
    background-color: #1C2438 !important;
}
.emailPannel{
    width: 50vw;
    height: fit-content;
    position: absolute;
    top:10vh;
    right:25vw;
    background : #0F172A;
    padding:4vh 2vw;
    z-index: 10000;
}
.ql-toolbar{
    margin : 0 1vw;

}

.ql-editor{
    margin : 0 2vw;

}
#emailPannelLoadingWrapper{
    height : 40vh !important
}
@media (max-width: 1000px) {
    .emailPannel {
            width:90vw;
            transform: translateX(20%);
        }}  