.composePannelWrapp{
        width: 50vw;
        height: fit-content;
        position: absolute;
        top:10vh;
        right:25vw;
        background : #0F172A;
        padding:7vh 2vw;   
        z-index: 10000;
}

@media (max-width: 1000px) {
    .composePannelWrapp {
            width:90vw;
            transform: translateX(20%);
        }}  

 .ql-editor {
    min-height: 20vh;
  }