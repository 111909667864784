/*
<--!----------------------------------------------------------------!-->
<--! Header CSS Start !-->
<--!----------------------------------------------------------------!-->
*/
.nxl-header {
	right: 0;
	z-index: 1025;
	position: fixed;
	left: $navigation-width;
	color: $brand-light;
	background: $white;
	min-height: $header-height;
	border-bottom: 1px solid $border-color;
	.header-wrapper {
		display: flex;
		padding: 0 30px;
		ul {
			margin-bottom: 0;
			display: inline-flex;
		}
		i {
			line-height: 1;
			font-size: $font-12;
		}
		.nxl-lavel-mega-menu-toggle,
		.nxl-navigation-toggle,
		.nxl-header-search {
			line-height: normal;
			a {
				color: $brand-dark;
			}
			i {
				line-height: 1;
				font-size: $font-24;
				&.feather-arrow-left {
					font-size: $font-20;
				}
			}
		}
		.nxl-h-item {
			min-height: 79px;
			display: flex;
			align-items: center;
			position: relative;
		}
		.nxl-head-link {
			margin-left: 3px;
			padding: 12px 12px;
			position: relative;
			display: flex;
			align-items: center;
			font-weight: $font-500;
			color: $header-link-color;
			border-radius: $radius-circle;
			> i {
				font-size: $font-20;
			}
			&.active,
			&:active,
			&:focus,
			&:hover {
				color: $primary;
				text-decoration: none;
				background: $brand-light;
				.hamburger {
					.hamburger-inner {
						background-color: $primary;
						&::after,
						&::before {
							background-color: $primary;
						}
					}
				}
			}
			&.nxl-language-link {
				padding: 15px 12px;
			}
			.nxl-h-badge {
				position: absolute;
				top: 0px;
				right: 5px;
				font-size: $font-9;
				border-radius: $radius-circle;
				&.dots {
					top: 8px;
					right: 12px;
					padding: 0;
					width: 8px;
					height: 8px;
				}
			}
		}
		.nxl-h-dropdown {
			width: 225px;
			top: 100% !important;
			transition: all 0.3s ease-in-out;
			&.dropdown-menu-end {
				right: 0 !important;
				left: auto !important;
			}
			&.nxl-user-dropdown {
				width: 300px;
			}
			.dropdown-header {
				margin-bottom: 10px;
				padding-bottom: 20px;
				padding-left: 25px;
				padding-right: 25px;
				border-bottom: 1px solid $border-color;
			}
			.dropdown-item {
				margin: 0 10px;
				border-radius: $radius-sm;
				width: -webkit-fill-available;
			}
			.fa-circle {
				font-size: $font-5;
				vertical-align: middle;
			}
		}
		.nxl-level-menu {
			position: relative;
			&:hover {
				> .dropdown-menu {
					left: 100%;
					display: block;
					top: 0px !important;
					transition: all 0.3s ease-in-out;
				}
			}
		}
		.nxl-mega-menu {
			position: static;
			transition: all 0.3s ease-in-out;
			.nxl-h-dropdown {
				left: 30px;
				width: 576px;
				max-width: 100%;
				padding: 0px 0px;
				&.nxl-mega-menu-sm {
					width: 576px;
					max-width: 100%;
				}
				&.nxl-mega-menu-md {
					width: 768px;
					max-width: 100%;
				}
				&.nxl-mega-menu-lg {
					width: 992px;
					max-width: 100%;
				}
				&.nxl-mega-menu-xl {
					width: 1200px;
					max-width: 100%;
				}
				&.nxl-mega-menu-xxl {
					width: 1400px;
					max-width: 100%;
				}
				&.nxl-mega-menu-full {
					width: 100%;
					max-width: 100%;
					left: 0 !important;
					right: 0 !important;
				}
				.nxl-mega-menu-tabs {
					width: 250px;
					min-width: 250px;
					max-width: 100%;
					padding: 25px 25px;
					.nav-link {
						border: none;
						margin-bottom: 5px;
						padding: 10px 15px;
						text-align: left;
						color: $brand-dark;
						font-size: $font-body;
						font-weight: $font-700;
						background-color: $white;
						border-radius: $radius-sm;
						transition: all 0.3s ease;
						display: flex;
						align-items: center;
						&.active {
							color: $darken;
							background-color: $brand-light;
							transition: all 0.3s ease;
						}
						.menu-icon {
							margin-right: 10px;
							flex-shrink: 0;
							display: flex;
							align-items: center;
							justify-content: center;
							i {
								font-size: $font-15;
							}
						}
						.menu-title {
							flex-grow: 1;
							display: flex;
							align-items: center;
						}
						.menu-arrow {
							margin-left: 5px;
							overflow: hidden;
							position: relative;
							display: flex;
							flex-shrink: 0;
							align-items: stretch;
						}
					}
				}
				.nxl-mega-menu-tabs-content {
					width: 100%;
					padding: 30px 30px;
					border-left: 1px solid $border-color;
					.dropdown-item-title {
						color: $darken;
						padding: 0 15px 5px;
					}
					.dropdown-item {
						margin: 0 0;
					}
					.menu-item-heading {
						font-size: $font-body;
					}
					.nxl-mega-menu-image {
						margin: auto auto;
						text-align: center;
						border-radius: $radius-lg;
						background-color: $brand-light;
						img {
							width: 100%;
							border-radius: $radius-lg;
							&.full-user-avtar {
								width: auto;
								height: 275px;
								padding: 25px 25px;
							}
						}
					}
					.nxl-mega-menu-integrations,
					.nxl-mega-menu-resources-right {
						.dropdown-item {
							display: flex;
							align-items: center;
							margin-bottom: 5px;
							border: 1px dashed $border-color;
							transition: all 0.3s ease;
							.menu-item-icon {
								width: 15px;
								margin-right: 10px;
								flex-shrink: 0;
								display: flex;
								align-items: center;
								justify-content: center;
								i {
									font-size: $font-15;
								}
							}
							.menu-item-title {
								flex-grow: 1;
								display: flex;
								align-items: center;
							}
							.menu-item-arrow {
								margin-left: 5px;
								overflow: hidden;
								position: relative;
								display: flex;
								flex-shrink: 0;
								align-items: stretch;
								i {
									margin-right: 0px;
								}
							}
						}
					}
					.nxl-mega-menu-resources {
						.dropdown-item-title,
						.dropdown-item {
							margin-bottom: 15px;
						}
						.dropdown-item:last-child {
							margin-bottom: 0px;
						}
						.carousel-inner {
							.carousel-item {
								.carousel-caption {
									.carousel-caption-title {
										color: $white;
									}
									.carousel-caption-desc {
										color: $white;
										font-size: $font-12;
									}
								}
							}
						}
					}
					.nxl-mega-menu-miscellaneous {
						margin-top: -30px;
						margin-left: -30px;
						margin-right: -30px;
						.nxl-mega-menu-miscellaneous-tabs {
							width: 100%;
							margin-bottom: 25px;
							.nav-item {
								.nav-link {
									border: none;
									text-align: left;
									color: $brand-dark;
									font-size: $font-body;
									font-weight: $font-700;
									padding: 15px 25px;
									border-radius: $radius-none;
									border-right: 1px solid $border-color;
									border-bottom: 1px solid $border-color;
									transition: all 0.3s ease;
									display: flex;
									align-items: center;
									&:first-child {
										margin-left: 0;
									}
									&.active {
										color: $primary;
										border-bottom: 1px solid $primary;
									}
									.menu-icon {
										width: 15px;
										margin-right: 10px;
										flex-shrink: 0;
										display: flex;
										align-items: center;
										justify-content: center;
										i {
											font-size: $font-15;
										}
									}
									.menu-title {
										flex-grow: 1;
										display: flex;
										align-items: center;
									}
								}
							}
						}
						.nxl-mega-menu-miscellaneous-content {
							padding: 15px 30px;
							.nxl-mega-menu-miscellaneous-services,
							.nxl-mega-menu-miscellaneous-features {
								i {
									font-size: $font-16;
								}
							}
							.nxl-mega-menu-miscellaneous-services {
								.carousel-inner {
									.carousel-item {
										.carousel-caption {
											.carousel-caption-title {
												color: $white;
											}
											.carousel-caption-desc {
												color: $white;
												font-size: $font-12;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.nxl-timesheets-menu {
			padding: 0;
			min-width: 20rem;
			.timesheets-head {
				padding: 20px;
				border-radius: 2px 2px 0 0;
				border-bottom: 1px solid $border-color;
			}
			.timesheets-body {
				padding: 30px 20px;
			}
			.timesheets-footer {
				padding: 15px 20px;
				border-radius: 0 0 2px 2px;
				border-top: 1px solid $border-color;
			}
		}
		.nxl-notifications-menu {
			padding: 0;
			min-width: 20rem;
			.notifications-head {
				padding: 20px;
				border-radius: 2px 2px 0 0;
				border-bottom: 1px solid $border-color;
			}
			.notifications-item {
				display: flex;
				cursor: pointer;
				padding: 15px 20px;
				transition: all 0.3s ease;
				+ .notifications-item {
					border-top: 1px dashed $border-color;
				}
				img {
					width: 60px;
					height: 60px;
				}
				.notifications-desc {
					flex: 1;
					.text-body {
						color: $brand-dark !important;
						margin-bottom: 5px;
						font-size: $font-13;
						font-weight: $font-400;
					}
				}
				.notifications-date {
					font-size: $font-11;
				}
			}
			.notifications-footer {
				padding: 15px 20px;
				border-radius: 0 0 2px 2px;
				border-top: 1px solid $border-color;
			}
		}
		.user-avtar {
			width: 40px;
			margin-right: 15px;
			border-radius: 50%;
		}
	}
}
html {
	&.minimenu {
		.nxl-header {
			left: $navigation-collapsed-width;
		}
	}
}
// header css end
